export default {

    SET_USER_CHAINES(state, value){
        state.user_chaines = value
    },

    SET_ALL_CHAINES(state, value){
        state.all_chaines = value
    },
    
    ADD_CHAINE(state,value){
        state.all_chaines.push(value)
    },

    UPDATE_CHAINE(state,value){
        state.all_chaines = state.all_chaines.map( el => {
            if(el.id === value.id)
                return value
            else
                return el
        })
    },

    DELETE_CHAINE(state, id){
        state.all_chaines = state.all_chaines.filter( el => el.id !== id)
    },

    SELECT_CHAINE(state,value){
        state.watched_chaine = `${process.env.VUE_APP_STREAM_HOST}${value}.m3u8`
    }
}