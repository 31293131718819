import {$http} from "@/plugins/axios"
import Cookies from "js-cookie"
export default {

  async register(ctx,payload){

    try {
      
        let res = await $http.post('/auth/register',payload)
        return {
          success: 1,
          data: res
        }
    } catch (e) {
      
      return {
        success: 0,
        message: e.response.data.message
      }
      
    }

  },

  async login(ctx,payload){

    try {
        let res = await $http.post('/auth/login',payload)
        return {
          success: 1,
          data: res.data.data
        }
    } catch (e) {
      console.log(e)
      return {
        success: 0,
        message: e.response.data.message
      }
      
    }

  },

  async get_user_infos(ctx){
    try {
      let res = await $http.get('/auth/get-infos')
      localStorage.setItem('user_infos',JSON.stringify(res.data))
    } catch (error) {
      
    }
  },

  async update_infos(ctx,payload){

    try {

      let res = await $http.put('/auth/update-infos',payload)
      localStorage.setItem('user_infos',JSON.stringify(res.data))

      return {
        success: 1,
        data: res.data
      }

    } catch (error) {

      return {
        success: 0,
        message: error.response.data.message
      }

    }
  },

  async sign_out(ctx){
    try {
      await $http.post('/auth/logout')
      Cookies.remove('accessToken')
    } catch (error) {
      
    }
  },

  async submit_email_recovery(ctx, payload){
    try {
      let res = await $http.post('/auth/recover-password',payload)
      return {
        success: 1,
        data: res.data
      }
    } catch (e) {
      return {
        success: 0,
        message: e.response.data.message
      }
    }
  },

  async get_user_stat(ctx){
    try {
      let res = await $http.get('/users-stat')
      return {
        success: 1,
        data: res.data
      }
    } catch (error) {
      return {
        success: 0,
        message: e.response.data.message
      }
    }
  },

  async revoke_account(ctx, id){

    try {
      let res = await $http.delete(`/auth/revoke-account/${id}`)
      return {
        success: 1,
        data: res.data
      }
    } catch (error) {
      return {
        success: 0,
        message: e.response.data.message
      }
    }
  }

 
  
}
