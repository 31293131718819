export default [
    {
        path: '/dashboardadmin',
        component: () => import('@/layouts/dashAdmin.vue'),
        children: [
            {
                path: '',
                name: 'dash-admin',
                meta: {
                    requiredAuth: true,
                    acl: 'admin'
                  },
                component: () => import('@/views/dashboardadmin/index.vue')
            },
            {
                path: 'demandes-de-diffusion',
                name: 'dash-admin-demandes',
                meta: {
                    requiredAuth: true,
                    acl: 'admin'
                  },
                component: () => import('@/views/dashboardadmin/demande.vue')
            },
            {
                path: 'chaines',
                name: 'dash-admin-chaines',
                meta: {
                    requiredAuth: true,
                    acl: 'admin'
                  },
                component: () => import('@/views/dashboardadmin/chaines.vue')
            },
            {
                path: 'bouquets',
                name: 'dash-admin-bouquets',
                meta: {
                    requiredAuth: true,
                    acl: 'admin'
                  },
                component: () => import('@/views/dashboardadmin/bouquets.vue')
            }
        ]
    }
]