import { $http } from "@/plugins/axios"
import { fileToBase64 } from "@/plugins/fileReader"
export default {
     
    async get_user_chaines(ctx){
        try {
            let res = await $http.get('/chaine/user')
            ctx.commit('SET_USER_CHAINES',res.data)
            return {
                success: 1,
            }
        } catch (e) {
            return {
                success: 0,
                message: e.response.data.message
            }
        }
    },

    async get_all_chaines(ctx){
        try {
            let res = await $http.get('/chaine/all')
            ctx.commit('SET_ALL_CHAINES',res.data)
            return {
                success: 1
            }
        } catch (error) {
            return {
                message: error.response.data.message
            }
        }
    },

    async add_chaine(ctx,payload){
        /**
         * @payload
         * name
         * email
         * bouquet_id
         */

        try {
            let res = await $http.post('/chaine/create',payload)
            ctx.commit('ADD_CHAINE',res.data.chaine)
            return {
                success:1
            }
        } catch (error) {
            return {
                message: error.response.data.message
            }
        }
    },

    async update_chaine(ctx, payload){
        /**
         * @payload
         * id
         * name
         * keyStream
         * bouquetId
         * allowed
         */

        try {
            let { id, ...data } = payload
            let res = await $http.put(`/chaine/update/${id}`,data)
            ctx.commit('UPDATE_CHAINE',res.data)
            return {
                success: 1
            }
        } catch (error) {
            return {
                message: error.response.data.message
            }
        }

    },

    async set_picture(ctx, {file}){
        try {

                if(file.size >= 1000000){
                    return {
                        success: 0,
                        message: "Taille du fichier supérieur à 1Mo"
                    }
                }
                let base64 = await fileToBase64(file)

                let res = await $http.put(`/chaine/set-picture`,{
                    base64,
                    extension: file.name.split('.').pop()
                })

                return {
                    success: 1,
                    data: res.data
                }

        } catch (error) {
            console.log(error)
            return {
                message: error.response.data.message
            }
        }
    },

    async delete_chaine(ctx, id){
        try {
            await $http.delete(`/chaine/delete/${id}`)
            ctx.commit('DELETE_CHAINE',id)
            return {
                success: 1
            }
        } catch (error) {
            return {
                message: error.response.data.message
            }
        }
    },


    async set_programme(ctx, {id,data}){
        try {
            await $http.post(`/programmes/${id}`,{
                programs: data
            })
            return {
                success: 1
            }
        } catch (error) {
            return {
                message: error.response.data.message
            }
        }
    },

    async get_programme(ctx,id){
        try {
            let res =  await $http.get(`/programmes/${id}`)
            return {
                success: 1,
                data: res.data
            }
        } catch (error) {
            return {
                message: error.response.data.message
            }
        }
    }
}