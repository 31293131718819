import { $http } from "@/plugins/axios"

export default {
    
    async get_bouquets(ctx){
        try {
            let res = await $http.get('/bouquet/all')
            ctx.commit('SET_BOUQUETS',res.data)
            return {
                success: 1,
                data: res.data
            }
        } catch (error) {
            return {
                success: 0,
                message: error.response.data.message
            }
        }
    },

    async add_bouquet(ctx,payload){
        /**
         * @payload
         * name
         * price
         */
        try {
            let res = await $http.post('/bouquet/create',payload)
            ctx.commit('ADD_BOUQUET',res.data)
            return {
                success: 1,
                data: res.data
            }
        } catch (error) {
            return {
                success: 0,
                message: error.response.data.message
            }
        }
    },

    async update_bouquet(ctx, payload){
        /**
         * @ayload
         * id
         * name
         * price
         */

        try {
            let {id, ...data} = payload
            let res = await $http.put(`/bouquet/update/${id}`,data)
            ctx.commit('UPDATE_BOUQUET',res.data)
            return {
                succes: 1,
                data: res.data
            }
        } catch (error) {
            return {
                success: 0,
                message: error.response.data.message
            }
        }
    },

    async delete_bouquet(ctx, id){
        /**
         * @paylaod
         * id
         */
        try {
            let res = await $http.delete(`/bouquet/delete/${id}`)
            ctx.commit('DELETE_BOUQUET',id)
            return {
                success: 1,
                data: res.data
            }
        } catch (error) {
            
            return {
                success: 0,
                message: error.response.data.message
            }
        
    }
    }

}