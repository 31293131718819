export default {

    SET_BOUQUETS(state, value){
        state.bouquets = value
    },

    ADD_BOUQUET(state, value){
        state.bouquets.push(value)
    },

    UPDATE_BOUQUET(state, value){
        state.bouquets = state.bouquets.map( el => {
            if(el.id === value.id )
                return value
            else
                return el
        })
    },

    DELETE_BOUQUET(state, id){
        state.bouquets = state.bouquets.filter( el => el.id !== id )
    }
}