import { $http } from "@/plugins/axios"

export default {

    async get_abonnement(ctx){
        try {
            let res = await $http.get('/abonnement')
            ctx.commit('SET_ABONNEMENT',res.data)
            return {
                success: 1
            }
        } catch (error) {
            return {
                success: 0,
                message: error.response.data.message
            }
        }
    },

    async make_abonnement(ctx, payload){
        try {
            let res  = await $http.post('/abonnement',payload)
            ctx.commit('SET_ABONNEMENT',res.data)
            return {
                success:1,
                data: res.data
            }
        } catch (error) {
            return {
                success: 0,
                message: error.response.data.message
            }
        }
    }
}