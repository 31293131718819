import mutations from "./mutations"
import actions from "./actions"
export default {

    namespaced: true,
    state(){
        return {
            user_chaines: [],
            all_chaines: [],
            watched_chaine: null,
        }
    },
    mutations,
    actions
    
}