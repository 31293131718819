import axios from "axios"
import Cookies from "js-cookie"

export const $http = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

$http.interceptors.request.use(
    config => {
        let token  = Cookies.get('accessToken')

        if(token)
            config.headers.Authorization = `Bearer ${token}`

        return config
    },
    error => Promise.reject(error)
)

$http.interceptors.response.use(
    async res => {
        return res 
    },
    async err => {
        if(err.response.data.errors){
            if(err.response.data.errors[0].message == "E_UNAUTHORIZED_ACCESS: "){
                let user = JSON.parse(localStorage.getItem('user_infos'))
                let res = await $http.post('/auth/refresh',{
                    refresh_token: Cookies.get('refreshToken'),
                    email: user.email
                })
                if(res.status === 200){
                    Cookies.set('accessToken',res.data.token)
                    Cookies.set('refreshToken',res.data.refreshToken)
                    window.location.reload()
                }else{
                    Cookies.remove('accessToken')
                    Cookies.remove('refreshToken')
                    localStorage.removeItem('user_infos')
                    window.location.replace('/login')
                }
            }
        }
        
        return Promise.reject(err)
    }
)

export default {
    install: (Vue, option) => {
        Vue.$http = $http
        Vue.prototype.$http = $http
    }
}
