import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth"
import chaines from "./chaines"
import abonnement from "./abonnement"
import bouquets from "./bouquets"
import demandes from './demandes'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    chaines,
    abonnement,
    bouquets,
    demandes
  }
})
