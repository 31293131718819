import Vue from 'vue'
import VueRouter from 'vue-router'
import chainesRoutes from './chaines'
import adminRoutes from './admin'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {},
    component: () => import('@/views/register.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {},
    component: () => import('@/views/login.vue')
  },
  {
    path: '/activate-account/:token',
    name:'activate-account',
    meta: {},
    component: () => import('@/views/activate-account.vue')
  },
  {
    path: '/recover-password/:token?',
    name:'recover-password',
    meta: {},
    component: () => import('@/views/recover-password.vue')
  },
  {
    path: '/politique-de-confidentialite',
    name:'p-conf',
    meta: {},
    component: () => import('@/views/politique-conf.vue')
  },
  {
    path: '/condition-d-utlisation',
    name:'usage',
    meta: {},
    component: () => import('@/views/usage.vue')
  },
  {
    path: '/condition-diffusion',
    name:'c-diffusion',
    meta: {},
    component: () => import('@/views/conditionchaine')
  },
  {
    path: '/a-propos',
    name: 'about',
    meta: {},
    component: () => import('@/views/about.vue')
  },
  {
    path: "/dashboard",
    name: 'dash',
    meta: {
      requiredAuth: true,
      acl: 'user'
    },
    component: () => import('@/views/dashboard/index.vue')
  },
  {
    path: "/profile",
    name: 'profile',
    meta: {
      requiredAuth: true,
      acl: 'user'
    },
    component: () => import('@/views/dashboard/profile.vue')
  },
  { 
    path: '/abonnement',
    name: 'abonnement',
    meta: {
      requiredAuth: true,
      acl: 'user'
    },
    component: () => import('@/views/dashboard/abonnement.vue')
  },
  ...chainesRoutes,
  ...adminRoutes

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( (to, from , next) => {
  if(to.matched.some( record => record.meta.requiredAuth )){
    if(Cookies.get('accessToken')){
      let user_infos = JSON.parse(localStorage.getItem('user_infos'))
      if(to.matched.some(record => record.meta.acl === user_infos.role )){
        next()
        return
      }
      history.back()
      return
    }
    next('/login')
    return
  }
  next()
  return


})
export default router
