import { $http } from "@/plugins/axios"

export default {

    async get_demandes(){
        try {
            let res = await $http.get('/demande')
            return {
                success: 1,
                data: res.data
            }
        } catch (error) {
            let msg = ''
            if(error.response.data.message){
                msg = error.response.data.message
            }else{
                msg = error.response.data.errors[0].message
            }
            return {
                message: msg
            }
        }
    },

    async new_demande(ctx,payload){
       
        try {
            let res = await $http.post('/demande/create',payload)
            return {
                success: 1,
                data: res.data
            } 
        } catch (error) {
            let msg = ''
            if(error.response.data.message){
                msg = error.response.data.message
            }else{
                msg = error.response.data.errors[0].message
            }
            return {
                message: msg
            }
        }
    },

    async accept_or_reject(ctx,payload){
        /**
         * @paylaod
         * accept
         * bouquet_id
         * id
         */
        try {
            let res = await $http.post('/demande/accept-or-reject/'+payload.id,payload)
            return {
                success: 1,
                data: res.data
            } 
        } catch (error) {
            return {
                message: error.response.data.message
            }
        }
    }

}