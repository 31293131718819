export default [
    {
        path: '/dashboardchaine',
        component: () => import('@/layouts/dashChaine.vue'),
        children: [
            {
                path: '',
                name: 'dash-chaine',
                meta: {
                    requiredAuth: true,
                    acl: 'chaine'
                  },
                component: () => import('@/views/dashboardchaine/index.vue')
            },
            {
                path: 'account',
                name: 'dash-chaine-account',
                meta: {
                    // requiredAuth: true,
                    // acl: 'chaine'
                  },
                component: () => import('@/views/dashboardchaine/account.vue')
            }
        ]
    }
]